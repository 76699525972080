@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"] {
		-webkit-appearance: none;
		margin: 0;
		-moz-appearance: textfield !important;
	}

	[data-radix-popper-content-wrapper] {
		z-index: 60 !important;
	}
}

* {
	@apply font-openSans
}


.ReactInputVerificationCode__item {
	border-bottom: 1px solid #D0D2D2 !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	width: 60px !important;
	height: 50px !important;
	display: flex !important;
	justify-content: center;
	align-items: center;
}

@media (min-width: 768px) {
	.ReactInputVerificationCode__container {
		width: 460px !important;
	}

}

@media (min-width: 1354px) {
	.ReactInputVerificationCode__container {
		width: 460px !important;
	}
}

@media (max-width: 768px) {
	.ReactInputVerificationCode__container {
		width: 320px !important;
	}

	.ReactInputVerificationCode__item {
		width: 40px !important;
		height: 30px !important;
	}
}

.is-active {
	border-bottom: 1px solid #00AC86 !important;
}